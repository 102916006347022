/** @jsx jsx */
import slugify from 'slugify';
import { Flex, Grid, Box, Heading, jsx } from 'theme-ui';

const BlogPostIndex = ({ blocks }) => {
  const sectionTitles = blocks.filter(
    block => block._type === 'postSectionTitle'
  );

  return (
    <Box
      sx={{
        py: 6,
        my: 7,
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderTopColor: 'gray2',
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: 'gray2',
      }}
    >
      <Heading as="h3" sx={{ display: 'block', mb: 4 }}>
        Liens rapides
      </Heading>
      <Grid columns={[1, 2]} gap={4}>
        {sectionTitles.map((sectionTitle, index) => (
          <Flex key={index} sx={{ alignItems: 'top' }}>
            <a
              href={`#${slugify(sectionTitle.title, {
                lower: true,
                strict: true,
              })}`}
            >
              {sectionTitle.title}
            </a>
          </Flex>
        ))}
      </Grid>
    </Box>
  );
};

export default BlogPostIndex;
