/** @jsx jsx */
import { useEffect, useState } from 'react';
import { GiphyFetch } from '@giphy/js-fetch-api';
import { Gif } from '@giphy/react-components';
import { Flex, jsx, Styled, Text } from 'theme-ui';

const getGiphyId = (url = '') => url.split('-').pop();

const BlockContentGiphyEmbed = ({ url }) => {
  const [gif, setGif] = useState(null);

  useEffect(() => {
    const gf = new GiphyFetch(process.env.GATSBY_GIPHY_TOKEN);
    const id = getGiphyId(url);
    const fetchGif = async () => {
      const { data } = await gf.gif(id);
      setGif(data);
    };

    fetchGif();
  }, [url]);

  if (!gif) return null;

  return (
    <Flex
      sx={{
        my: 6,
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      <Gif gif={gif} width={568} backgroundColor="white" noLink />
      <Text sx={{ mt: 3, fontSize: 1 }}>
        <Styled.a
          href={gif.url}
          sx={{ color: 'gray4', textDecoration: 'none' }}
          target="_blank"
          rel="noopener"
        >
          via GIPHY
        </Styled.a>
      </Text>
    </Flex>
  );
};

export default BlockContentGiphyEmbed;
