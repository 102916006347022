/** @jsx jsx */
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FiChevronLeft } from 'react-icons/fi';
import { Grid, Heading, Box, Text, jsx, Flex } from 'theme-ui';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
} from 'react-share';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import BlockContent from '../../components/BlockContent';
import BlogPostIndex from '../../components/BlogPostIndex';
import BlogPostCategories from '../../components/BlogPostCategories';
import BlogPostTileRelated from '../../components/BlogPostTileRelated';
import TeamBlockContent from '../../components/TeamBlockContent';

const BlogPostTemplate = ({ data: { post, relatedPosts }, location }) => {
  return (
    <Layout location={location}>
      <SEO title={`${post.title} | Blog`} />
      <Text
        as={Link}
        to="/blog"
        sx={{
          display: 'flex',
          alignItems: 'center',
          textDecoration: 'none',
          gridColumnStart: [1],
          gridColumnEnd: [2, 3, 5, 13],
        }}
      >
        <FiChevronLeft sx={{ mr: 1 }} />
        Blog
      </Text>
      <Box
        sx={{
          gridColumnStart: [1, null, null, 3],
          gridColumnEnd: [2, 3, 5, 11],
        }}
      >
        <BlogPostCategories categories={post.categories} />
        {post.title && (
          <Heading
            sx={{
              fontSize: [5, 6],
              mb: [0, null, 2, 4],
            }}
          >
            {post.title}
          </Heading>
        )}
      </Box>
      {post.mainImage && (
        <Box
          sx={{
            gridColumnStart: [1, null, null, 1],
            gridColumnEnd: [2, 3, 5, 13],
            mb: [0, null, 2, 4],
          }}
        >
          <GatsbyImage
            image={post.mainImage.asset.gatsbyImageData}
            alt=""
            sx={{ borderRadius: 3 }}
          />
        </Box>
      )}
      <Box
        sx={{
          gridColumnStart: [1, null, 2, 4],
          gridColumnEnd: [2, 3, 4, 10],
        }}
      >
        <Text sx={{ mb: 6, fontSize: 3 }}>{post.intro}</Text>
        <BlogPostIndex blocks={post._rawBody} />
        <Box sx={{ mb: 6 }}>
          <BlockContent blocks={post._rawBody} />
        </Box>
        <Box
          sx={{
            py: 7,
            mb: 6,
            borderTopWidth: '1px',
            borderRightWidth: 0,
            borderBottomWidth: '1px',
            borderLeftWidth: 0,
            borderStyle: 'solid',
            borderColor: 'gray2',
          }}
        >
          <Flex
            sx={{
              justifyContent: 'center',
            }}
          >
            <Box sx={{ mx: 1 }}>
              <FacebookShareButton url={location.href}>
                <FacebookIcon size={40} round />
              </FacebookShareButton>
            </Box>
            <Box sx={{ mx: 1 }}>
              <TwitterShareButton url={location.href}>
                <TwitterIcon size={40} round />
              </TwitterShareButton>
            </Box>
            <Box sx={{ mx: 1 }}>
              <WhatsappShareButton url={location.href}>
                <WhatsappIcon size={40} round />
              </WhatsappShareButton>
            </Box>
            <Box sx={{ mx: 1 }}>
              <LinkedinShareButton url={location.href}>
                <LinkedinIcon size={40} round />
              </LinkedinShareButton>
            </Box>
          </Flex>
          {post.author && (
            <Flex
              sx={{
                mt: 6,
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <GatsbyImage
                image={post.author.image.asset.gatsbyImageData}
                sx={{ borderRadius: '50%', mb: 3 }}
              />
              <Text sx={{ fontSize: 2, variant: 'text.heading' }}>
                {post.author.name}
              </Text>
              <TeamBlockContent blocks={post.author._rawBio} />
            </Flex>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          gridColumnStart: [1],
          gridColumnEnd: [2, 3, 5, 13],
        }}
      >
        <Heading sx={{ mb: 5, fontSize: 6 }}>Plus d'articles</Heading>
        <Grid columns={[1, 2, 4]} gap={[4, 5]}>
          {relatedPosts.nodes.map(relatedPost => (
            <BlogPostTileRelated key={relatedPost.id} post={relatedPost} />
          ))}
        </Grid>
      </Box>
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      title
      mainImage {
        asset {
          gatsbyImageData(width: 1200, height: 400)
        }
      }
      categories {
        id
        title
        color
        slug {
          current
        }
      }
      intro
      _rawBody(resolveReferences: { maxDepth: 10 })
    }
    relatedPosts: allSanityPost(
      filter: {
        id: { ne: $id }
        # categories: { elemMatch: { id: { in: $categoryIds } } }
      }
      limit: 4
    ) {
      nodes {
        id
        title
        slug {
          current
        }
        mainImage {
          asset {
            gatsbyImageData(width: 600, height: 500)
          }
        }
        intro
        categories {
          id
          title
          color
        }
      }
    }
  }
`;

export default BlogPostTemplate;
