/** @jsx jsx */
import { Link } from 'gatsby';
import { Box, Text, Heading, Button, jsx } from 'theme-ui';
import { blogPostUrl } from '../lib/urls';
import BlogPostCategories from './BlogPostCategories';
import BlogPostTileImage from './BlogPostTileImage';

const BlogPostTile = ({ post }) => {
  const postUrl = blogPostUrl(post.slug?.current);

  return (
    <Box as="article" sx={{ px: 0, mb: 4 }}>
      <Link to={postUrl} sx={{ mb: 4, display: 'block' }}>
        <BlogPostTileImage post={post} bgOffset={0} />
      </Link>
      <BlogPostCategories categories={post.categories} />
      {post.title && (
        <Link to={postUrl} sx={{ textDecoration: 'none' }}>
          <Heading as="h2" sx={{ fontSize: 4, mb: 3 }}>
            {post.title}
          </Heading>
        </Link>
      )}
      <Text sx={{ mb: 3 }}>{post.intro}</Text>
      <Button as={Link} to={postUrl} variant="secondary" sx={{ fontSize: 1 }}>
        Plus d'info
      </Button>
    </Box>
  );
};

export default BlogPostTile;
