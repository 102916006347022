import React from 'react';
import BaseBlockContent from '@sanity/block-content-to-react';
import { Styled, Box } from 'theme-ui';
import slugify from 'slugify';
import BlockContentFigure from './BlockContentFigure';
import BlockContentImage from './BlockContentImage';
import BlockContentInstagramPost from './BlockContentInstagramPost';
import BlockContentYouTubeVideo from './BlockContentYouTubeVideo';
import BlockContentGiphyEmbed from './BlockContentGiphyEmbed';

const serializers = {
  types: {
    block(props) {
      switch (props.node.style) {
        case 'h2':
          return <Styled.h2>{props.children}</Styled.h2>;
        case 'h3':
          return <Styled.h3>{props.children}</Styled.h3>;
        case 'h4':
          return <Styled.h4>{props.children}</Styled.h4>;
        case 'h5':
          return <Styled.h5>{props.children}</Styled.h5>;
        case 'blockquote':
          return (
            <Box
              as="blockquote"
              sx={{
                display: 'block',
                my: 6,
                p: 5,
                borderTop: '1px solid #eee',
                borderBottom: '1px solid #eee',
                fontWeight: 'bold',
                fontSize: 3,
                color: 'primary',
              }}
            >
              {props.children}
            </Box>
          );
        default:
          return <Styled.p>{props.children}</Styled.p>;
      }
    },
    postSectionTitle: props => (
      <Styled.h2 id={slugify(props.node.title, { lower: true, strict: true })}>
        {props.node.title}
      </Styled.h2>
    ),
    figure: props => <BlockContentFigure figure={props.node} />,
    image: props => <BlockContentImage image={props.node} />,
    instagramPost: props => <BlockContentInstagramPost url={props.node.url} />,
    youtubeVideo: props => <BlockContentYouTubeVideo url={props.node.url} />,
    giphyEmbed: props => <BlockContentGiphyEmbed url={props.node.url} />,
  },
  list: props => <Styled.ul {...props} />,
  listItem: props => <Styled.li {...props} />,
};

const BlockContent = ({ blocks }) => (
  <BaseBlockContent blocks={blocks} serializers={serializers} />
);

export default BlockContent;
