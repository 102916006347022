import React from 'react';
import getYouTubeId from 'get-youtube-id';
import { Box } from 'theme-ui';

const BlockContentYouTubeVideo = ({ url }) => {
  const id = getYouTubeId(url);

  return (
    <Box sx={{ my: 6 }}>
      <iframe
        width="100%"
        height="360"
        src={`https://www.youtube-nocookie.com/embed/${id}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        loading="lazy"
        title="YouTube Video"
      ></iframe>
    </Box>
  );
};

export default BlockContentYouTubeVideo;
