import React from 'react';
import InstagramEmbed from 'react-instagram-embed';
import { Box } from 'theme-ui';

const BlockContentInstagramPost = ({ url }) => {
  return (
    <Box sx={{ my: 6 }}>
      <InstagramEmbed url={url} />
    </Box>
  );
};

export default BlockContentInstagramPost;
