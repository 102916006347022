/** @jsx jsx */
import { GatsbyImage } from 'gatsby-plugin-image';
import { jsx } from 'theme-ui';
import { getGatsbyImageData } from 'gatsby-source-sanity';

const BlockContentFigure = ({ figure }) => {
  const gatsbyImageData = getGatsbyImageData(
    figure.asset?.id,
    { maxWidth: 1200 },
    {
      projectId: process.env.GATSBY_SANITY_PROJECT_ID,
      dataset: process.env.GATSBY_SANITY_PROJECT_DATASET,
    }
  );

  return (
    <figure
      sx={{
        my: 7,
        mx: [0, null, -7],
      }}
    >
      <GatsbyImage
        image={gatsbyImageData}
        alt={figure.alt}
        sx={{ borderRadius: 3, mb: 3 }}
      />
      <figcaption sx={{ textAlign: 'center', fontSize: 1, color: 'gray4' }}>
        {figure.caption}
      </figcaption>
    </figure>
  );
};

export default BlockContentFigure;
