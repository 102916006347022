/** @jsx jsx */
import { GatsbyImage } from 'gatsby-plugin-image';
import { jsx } from 'theme-ui';
import { getGatsbyImageData } from 'gatsby-source-sanity';

const BlockContentImage = ({ image }) => {
  const gatsbyImageData = getGatsbyImageData(
    image.asset?.id,
    { maxWidth: 1200 },
    {
      projectId: process.env.GATSBY_SANITY_PROJECT_ID,
      dataset: process.env.GATSBY_SANITY_PROJECT_DATASET,
    }
  );

  return (
    <GatsbyImage
      image={gatsbyImageData}
      alt=""
      sx={{ display: 'block', my: 7 }}
    />
  );
};

export default BlockContentImage;
